export const environment = {
  production: true,
  apiUrl: 'https://gap-api.golfglobe.com/api/', // production link
  webHookUrl: 'https://golfglobe.com/wp-json/wp-gap/v1/',
  token : 'Z29sZmdsb2JlLmNvbV9nYXBfYXNfYV9wcm9kdWN0aW9uX3N5c3RlbV93',
  firebaseConfig: {
    apiKey: 'AIzaSyB-tIlzMdAFPElAmpUG6b3oBEPj5waB0F8',
    authDomain: 'gap-app-6edc1.firebaseapp.com',
    projectId: 'gap-app-6edc1',
    storageBucket: 'gap-app-6edc1.appspot.com',
    messagingSenderId: '930462278538',
    appId: '1:930462278538:web:7d3ec231e1b8871b009e73',
    measurementId: 'G-SLMJRJT6QS'
  }
};
